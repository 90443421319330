@charset "utf-8";
/* font */
@import url(../css/font.css);

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

[class^="material-icons"] {
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}


.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* reset */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
button {
  margin: 0;
  padding: 0;
}
fieldset,
img {
  border: 0 none;
}
dl,
ul,
ol,
menu,
li {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
input,
select,
textarea,
button {
  vertical-align: middle;
}
input::-ms-clear {
  display: none;
}
button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}
body {
  overflow-x: auto;
  min-width: 1290px;
  background: #fff;
}
body,
th,
td,
input,
select,
textarea,
pre,
button {
  font-size: 12px;
  line-height: 1.5;
  font-family: "Noto Sans KR", AppleSDGothicNeo-Regular, "Malgun Gothic", "맑은 고딕", sans-serif;
  color: #555;
  -webkit-text-size-adjust: none;
}
a {
  color: #555;
  text-decoration: none;
}
/* a:active, a:hover{text-decoration:underline} */
a:active {
  background-color: transparent;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}

/* global */
#kakaoIndex {
  overflow: hidden;
  position: absolute;
  left: -9999px;
  width: 0;
  height: 1px;
  margin: 0;
  padding: 0;
}
.ir_pm {
  display: block;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}
.ir_caption {
  overflow: hidden;
  width: 1px;
  font-size: 1px;
  line-height: 0;
  text-indent: -9999px;
}
.screen_out {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  text-indent: -9999px;
}
.show {
  display: block;
}
.hide {
  display: none;
}

html,
body,
#app,
#kakaoWrap {
  height: 100%;
}

/* -- img -- */
.img_g {
  display: block;
}
/* -- width -- */
.w100p {
  width: 100% !important;
}
.w20 {
  width: 20px !important;
}
.w27 {
  width: 27px !important;
}
.w40 {
  width: 40px !important;
}
.w48 {
  width: 48px !important;
}
.w56 {
  width: 56px !important;
}
.w70 {
  width: 70px !important;
}
.w72 {
  width: 72px !important;
}
.w80 {
  width: 80px !important;
}
.w90 {
  width: 90px !important;
}
.w96 {
  width: 96px !important;
}
.w98 {
  width: 98px !important;
}
.w100 {
  width: 100px !important;
}
.w104 {
  width: 104px !important;
}
.w105 {
  width: 105px !important;
}
.w113 {
  width: 113px !important;
}
.w120 {
  width: 120px !important;
}
.w121 {
  width: 121px !important;
}
.w130 {
  width: 130px !important;
}
.w134 {
  width: 134px !important;
}
.w137 {
  width: 137px !important;
}
.w140 {
  width: 140px !important;
}
.w152 {
  width: 152px !important;
}
.w164 {
  width: 164px !important;
}
.w170 {
  width: 170px !important;
}
.w188 {
  width: 188px !important;
}
.w193 {
  width: 193px !important;
}
.w194 {
  width: 194px !important;
}
.w208 {
  width: 208px !important;
}
.w210 {
  width: 210px !important;
}
.w220 {
  width: 220px !important;
}
.w270 {
  width: 270px !important;
}
.w282 {
  width: 282px !important;
}
.w295 {
  width: 295px !important;
}
.w297 {
  width: 297px !important;
}
.w300 {
  width: 300px !important;
}
.w307 {
  width: 307px !important;
}
.w316 {
  width: 316px !important;
}
.w320 {
  width: 320px !important;
}
.w336 {
  width: 336px !important;
}
.w370 {
  width: 370px !important;
}
.w380 {
  width: 380px !important;
}
.w388 {
  width: 388px !important;
}
.w396 {
  width: 396px !important;
}
.w399 {
  width: 399px !important;
}
.w400 {
  width: 400px !important;
}
.w405 {
  width: 405px !important;
}
.w420 {
  width: 420px !important;
}
.w424 {
  width: 424px !important;
}
.w425 {
  width: 425px !important;
}
.w437 {
  width: 437px !important;
}
.w448 {
  width: 448px !important;
}
.w452 {
  width: 452px !important;
}
.w455 {
  width: 455px !important;
}
.w494 {
  width: 494px !important;
}
.w560 {
  width: 560px !important;
}
.w564 {
  width: 564px !important;
}
.w588 {
  width: 588px !important;
}
.w612 {
  width: 612px !important;
}
.w690 {
  width: 690px !important;
}
.w812 {
  width: 812px !important;
}

/* -- height -- */
.h77 {
  height: 77px !important;
}
.h84 {
  height: 84px !important;
}
.h96 {
  height: 96px !important;
}
.h473 {
  height: 473px !important;
}

/* -- color -- */
.tc_red {
  color: #f8626c !important;
}
.tc_b {
  color: #bbb !important;
}

/* -- ico -- */
.ico_purchase {
  display: inline-block;
  overflow: hidden;
  background: url("../images/ico_purchase_210324.png") no-repeat;
  background-size: 120px 280px;
  font-size: 1px;
  line-height: 0;
  color: transparent;
  text-indent: -9999px;
  vertical-align: top;
}
/* arrow - 상하 */
.ico_arr_profile {
  width: 6px;
  height: 4px;
  background-position: 0 -10px;
}
.k_head .menu_profile.on .ico_arr_profile {
  background-position: 0 0;
}
.ico_select {
  width: 9px;
  height: 6px;
  background-position: -10px -10px;
}
.is-focus .ico_select {
  background-position: -10px 0;
}
.select_comm .is-disabled .ico_select {
  background-position: -20px -10px;
}
.area_login .ico_select {
  width: 10px;
  height: 6px;
  background-position: -30px -10px;
}
.area_login .is-focus .ico_select {
  background-position: -30px 0;
}
/* btn_fold */
.ico_arr_fold {
  width: 10px;
  height: 6px;
  background-position: 0 -20px;
}
.folded .ico_arr_fold {
  background-position: 0 -30px;
}
.btn_fold:hover .ico_arr_fold {
  background-position: -10px -20px;
}
.folded .btn_fold:hover .ico_arr_fold {
  background-position: -10px -30px;
}
.ico_arr_fold_gray {
  width: 8px;
  height: 5px;
  background-position: -20px -20px;
}
.folded .ico_arr_fold_gray {
  background-position: -20px -30px;
}
.btn_fold:hover .ico_arr_fold_gray {
  background-position: -30px -20px;
}
.folded .btn_fold:hover .ico_arr_fold_gray {
  background-position: -30px -30px;
}
/* bar_fold */
.ico_arr_fold2 {
  width: 11px;
  height: 5px;
  background-position: -40px -20px;
}
.folded .ico_arr_fold2 {
  background-position: -40px -30px;
}
.bar_fold:hover .ico_arr_fold {
  background-position: -60px -20px;
}
.folded .bar_fold:hover .ico_arr_fold {
  background-position: -60px -30px;
}
.ico_arr_fold2_gray {
  width: 11px;
  height: 5px;
  background-position: -80px -20px;
}
.folded .ico_arr_fold2_gray {
  background-position: -80px -30px;
}
/* paging */
.ico_arr_prev {
  width: 10px;
  height: 10px;
  background-position: 0 -40px;
}
.ico_arr_next {
  width: 10px;
  height: 10px;
  background-position: -10px -40px;
}
.ico_arr_first {
  width: 10px;
  height: 10px;
  background-position: 0 -50px;
}
.ico_arr_last {
  width: 10px;
  height: 10px;
  background-position: -10px -50px;
}
/* arrow - 좌우 */
.ico_arr_join {
  width: 6px;
  height: 10px;
  background-position: -20px -40px;
}
.ico_arr_connect {
  width: 6px;
  height: 10px;
  background-position: -20px -50px;
}
/* tooptip */
.ico_arr_tooptip {
  width: 9px;
  height: 9px;
  background-position: -30px -40px;
}
/* star */
.ico_star {
  width: 5px;
  height: 5px;
  margin: 1px 0 12px 4px;
  background-position: -30px -50px;
}
/* state change */
.ico_state_change_ing {
  width: 8px;
  height: 14px;
  background-position: -70px -40px;
}
.ico_state_change_end {
  width: 1px;
  height: 14px;
  background-position: -80px -40px;
}
/* in button */
.ico_check_w {
  width: 9px;
  height: 7px;
  background-position: -40px -40px;
}
.ico_cross_w {
  width: 9px;
  height: 9px;
  background-position: -40px -50px;
}
/* dialogue */
.ico_dialouge {
  width: 8px;
  height: 8px;
  background-position: -50px -40px;
}
/* cross */
.ico_cross {
  width: 18px;
  height: 18px;
  background-position: 0 -60px;
}
.ico_cross2 {
  width: 20px;
  height: 20px;
  background-position: -20px -60px;
}
.ico_cross3 {
  width: 8px;
  height: 8px;
  background-position: -40px -60px;
}
/* drag */
.ico_multi {
  width: 4px;
  height: 12px;
  background-position: -50px -60px;
}
.ico_drag {
  width: 16px;
  height: 11px;
  background-position: -60px -60px;
}
/* preview */
.ico_preview {
  width: 12px;
  height: 14px;
  background-position: -80px -60px;
}
/* exclam */
.ico_exclam {
  width: 16px;
  height: 16px;
  background-position: 0 -80px;
}
.ico_exclam_r {
  width: 16px;
  height: 16px;
  background-position: -20px -80px;
}
.ico_tooptip {
  width: 16px;
  height: 16px;
  background-position: -40px -80px;
}
/* search */
.ico_search {
  width: 16px;
  height: 15px;
  background-position: -60px -80px;
}
.ico_search_s {
  width: 14px;
  height: 13px;
  background-position: -80px -80px;
}
/* calendar */
.ico_calendar {
  width: 10px;
  height: 11px;
  background-position: -100px -80px;
}
.is-disabled ~ .ico_calendar {
  background-position: -110px -80px;
}
/* download */
.ico_download {
  width: 15px;
  height: 17px;
  background-position: 0 -100px;
}
.ico_download_w {
  width: 11px;
  height: 10px;
  background-position: -20px -100px;
}
/* catagory */
.ico_check_save {
  width: 18px;
  height: 14px;
  background-position: -40px -100px;
}
.ico_edit {
  width: 20px;
  height: 20px;
  background-position: -60px -100px;
}
.ico_delete {
  width: 20px;
  height: 20px;
  background-position: -80px -100px;
}

/* checkbox */
.check_comm .ico_checkbox {
  width: 16px;
  height: 16px;
  background-position: 0 -120px;
}
.check_comm input:checked + label .ico_checkbox {
  background-position: -20px -120px;
}
.check_comm input:disabled + label .ico_checkbox {
  background-position: -40px -120px;
}
.check_comm input:disabled:checked + label .ico_checkbox {
  background-position: -60px -120px;
}
/* radio */
.radio_comm .ico_radio {
  width: 16px;
  height: 16px;
  background-position: 0 -140px;
}
.radio_comm input:checked + label .ico_radio {
  background-position: -20px -140px;
}
.radio_comm input:disabled + label .ico_radio {
  background-position: -40px -140px;
}
.radio_comm input:disabled:checked + label .ico_radio {
  background-position: -60px -140px;
}
.radio_box_item .ico_raido_check {
  width: 9px;
  height: 7px;
  background-position: -80px -140px;
}
.radio_box_item input:checked + label .ico_raido_check {
  background-position: -80px -150px;
}
/* PDF */
.ico_pdf {
  width: 16px;
  height: 16px;
  background-position: 0 -160px;
}
.ico_png {
  width: 16px;
  height: 16px;
  background-position: -20px -160px;
}
.ico_jpg {
  width: 16px;
  height: 16px;
  background-position: -40px -160px;
}
.ico_zip {
  width: 16px;
  height: 16px;
  background-position: -60px -160px;
}
.ico_etc {
  width: 16px;
  height: 16px;
  background-position: -80px -160px;
}
.file_uploaded .ico_pdf {
  background-position: 0 -180px;
}
.file_uploaded .ico_png {
  background-position: -20px -180px;
}
.file_uploaded .ico_jpg {
  background-position: -40px -180px;
}
.file_uploaded .ico_zip {
  background-position: -60px -180px;
}
.file_uploaded .ico_etc {
  background-position: -80px -180px;
}
/* connect */
/* .ico_connect{width:32px;height:32px}
.connect_edit .ico_connect{background-position:0 -200px}
.connect_view .ico_connect{background-position:-40px -200px} */
.ico_connect_sm {
  width: 22px;
  height: 22px;
  background-position: -80px -200px;
}
.list_connect li.disabled .ico_connect_sm {
  background-position: -80px -240px;
}
/* ellip */
.ico_arr_ellip {
  width: 24px;
  height: 24px;
  background-position: 0 -240px;
}
.open .ico_arr_ellip {
  width: 24px;
  height: 24px;
  background-position: -30px -240px;
}

/* -- button -- */
button:disabled {
  cursor: not-allowed;
}
.btn_large {
  display: inline-block;
  min-width: 120px;
  height: 40px;
  padding: 9px 32px 11px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  vertical-align: top;
  box-sizing: border-box;
}
.btn_medium {
  display: inline-block;
  min-width: 104px;
  height: 32px;
  padding: 6px 16px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  vertical-align: top;
  box-sizing: border-box;
}
.btn_small {
  display: inline-block;
  height: 32px;
  padding: 5px 16px 7px;
  font-weight: normal;
  line-height: 18px;

  vertical-align: top;
  box-sizing: border-box;
}
/* 아이콘 */
.btn_medium .ico_purchase {
  margin: 0 8px 2px 0;
  vertical-align: middle;
}
.btn_medium .ico_download_w {
  margin: 3px 7px 4px 0;
}
/* primary */
.btn_primary {
  border-radius: 2px;
  background-color: #ffcd00;
  color: #222;
}
.btn_primary:hover {
  background-color: #e5b800;
}
.btn_primary:disabled {
  background-color: #ffe67f;
  color: #bbb;
}
.btn_primary.btn_small {
  font-weight: 500;
}
/* secondary */
.btn_secondary {
  border-radius: 2px;
  background-color: #666;
  color: #fff;
}
.btn_secondary:hover {
  background-color: #555;
}
.btn_secondary:disabled {
  background-color: #666;
  color: #888;
}
.btn_secondary.btn_small {
  font-weight: 500;
}
/* tertiary */
.btn_tertiary {
  border-radius: 2px;
  background-color: #5551ce;
  color: #fff;
}
.btn_tertiary:hover {
  background-color: #4a47a5;
}
.btn_tertiary:disabled {
  background-color: #5551ce;
  color: #888;
}
.btn_tertiary.btn_small {
  font-weight: 500;
}
/* fourthy */
.btn_fourthly {
  border-radius: 2px;
  border: 1px solid #bbb;
  background-color: #fff;
  color: #222;
}
.btn_fourthly:hover {
  border-color: #888;
}
.btn_fourthly:disabled {
  border-color: #e1e1e1;
  color: #bbb;
}
.btn_fourthly.btn_medium:disabled,
.btn_fourthly.btn_small:disabled {
  background-color: #fbfbfb;
}

.btn_fifthly {
  border-radius: 2px;
  border: 1px solid #f8626c;
  background-color: #fbfbfb;
  color: #f8626c;
}
.btn_fifthly:hover {
  border-color: #c93d3d;
}
.btn_fifthly:disabled {
  border-color: #ffcece;
  color: #ffcece;
}
.btn_fifthly.btn_medium:disabled,
.btn_fifthly.btn_small:disabled {
  background-color: #fbfbfb;
}
/* search */
.btn_search {
  display: inline-block;
  height: 32px;
  padding: 9px 7px 8px 9px;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
}

/* -- badge -- */
.badge_state {
  display: inline-block;
  overflow: hidden;
  min-width: 46px;
  padding: 0 8px;
  border-radius: 9px;
  border: 1px solid #e1e1e1;
  font-size: 12px;
  line-height: 18px;
  color: #555;
  vertical-align: top;
  text-align: center;
}
.badge_state.on {
  border-color: #bbb;
}
.badge_state.disabled {
  border-color: #e1e1e1;
  background-color: #fbfbfb;
  color: #888;
}

/* -- 드롭박스 -- */
div.box_drop {
  margin: 4px 0 !important;
  border: 1px solid #555;
  border-radius: 2px;
  box-shadow: none;
}
.box_drop .tit_drop {
  display: block;
  overflow: hidden;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #222;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.box_drop .txt_drop {
  display: block;
  overflow: hidden;
  font-size: 12px;
  line-height: 18px;
  color: #888;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.box_drop .desc_empty {
  padding: 50px 0;
  text-align: center;
  color: #555;
}
/* 드롭박스_셀렉스박스 */
.box_drop .el-select-dropdown__list {
  border-radius: 2px;
}
.box_drop .el-select-dropdown__list li {
  height: 32px;
  padding: 6px 32px 6px 12px;
  font-size: 12px;
  line-height: 20px;
  color: #555;
}
.box_drop .el-select-dropdown__list li.selected {
  font-weight: normal;
}
.box_drop .el-select-dropdown__list li.hover,
.box_drop .el-select-dropdown__list li:hover,
.box_drop .el-select-dropdown__list li.selected,
.box_drop .el-select-dropdown.is-multiple .el-select-dropdown__list li.selected.hover {
  background-color: #fffbed;
  color: #555;
}
.box_drop .el-select-dropdown.is-multiple .el-select-dropdown__list li.selected {
  color: #555;
}
.box_drop .el-select-dropdown__list li.is-disabled,
.box_drop .el-select-dropdown__list li.disabled.hover,
.box_drop .el-select-dropdown__list li.disabled:hover {
  background-color: #fbfbfb;
  color: #bbb;
}
/* 드롭박스_검색 */
.area_search .box_drop {
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  max-height: 282px;
  padding: 7px 0;
  border-radius: 0;
  border: 1px solid #555;
  background-color: #fff;
}
.area_search .box_drop .btn_drop {
  display: block;
  width: 100%;
  padding: 11px 15px 9px;
  text-align: left;
}
.area_search .box_drop .btn_drop:hover,
.area_search .box_drop .btn_drop.selected {
  background-color: #fffbed;
}
.area_search .box_drop .btn_drop + .btn_drop {
  margin: 0;
}
/* 드롭박스_아이콘 */
div.box_drop .popper__arrow {
  display: none;
}
div.box_drop .popper__arrow::after {
  content: none;
}
/* 캘린더 드롭박스 */
.el-picker-panel.el-popper {
  margin-top: 4px !important;
  border-radius: 2px;
  border: 1px solid #555;
}
.el-picker-panel.el-popper .el-date-range-picker__content.is-left {
  border-right: 1px solid #555;
}
/* 캘린더 드롭박스 아이콘 */
.el-picker-panel.el-popper .popper__arrow {
  display: none;
}
/* 캘린더 외부 input */
.el-picker-panel.el-popper .el-input__inner {
  padding: 0 12px;
  border-radius: 2px;
  border: 1px solid #e1e1e1;
  color: #555;
}
.el-picker-panel.el-popper .el-input__inner::-webkit-input-placeholder {
  color: #bbb;
}
.el-picker-panel.el-popper .el-input__inner:-moz-placeholder {
  color: #bbb;
}
.el-picker-panel.el-popper .el-input__inner::-moz-placeholder {
  color: #bbb;
}
.el-picker-panel.el-popper .el-input__inner:-ms-input-placeholder {
  color: #bbb;
}
.el-picker-panel.el-popper .el-input__inner:focus-within {
  border: 1px solid #555;
}
/* 캘린더 년월 */
.el-picker-panel.el-popper .el-date-picker__header-label.active,
.el-picker-panel.el-popper .el-date-picker__header-label:hover {
  color: #5551ce;
}
/* 캘린더 일 */
.el-picker-panel.el-popper .el-date-table td.available:hover {
  color: #5551ce;
}
.el-picker-panel.el-popper .el-date-table td.current:not(.disabled) span {
  color: #222;
  background-color: #ffcd00;
}
.el-picker-panel.el-popper .el-date-table td.end-date span,
.el-picker-panel.el-popper .el-date-table td.start-date span {
  color: #222;
  background-color: #ffcd00;
}
.el-picker-panel.el-popper .el-date-table td.in-range div,
.el-picker-panel.el-popper .el-date-table td.in-range div:hover,
.el-picker-panel.el-popper .el-date-table.is-week-mode .el-date-table__row.current div,
.el-picker-panel.el-popper .el-date-table.is-week-mode .el-date-table__row:hover div {
  background-color: #fff8d9;
}
.el-picker-panel.el-popper .el-date-table td.today span {
  color: #222;
}
/* 캘린더 시간 드롭박스 */
.el-picker-panel.el-popper .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background-color: #fff8d9;
}
.el-picker-panel.el-popper .el-time-panel__btn {
  margin: 0;
  padding: 0 16px;
  border: 1px solid #bbb;
  border-radius: 2px;
  line-height: 26px;
  color: #555;
}
.el-picker-panel.el-popper .el-time-panel__btn.confirm {
  font-weight: normal;
}
.el-picker-panel.el-popper .el-time-panel__btn:hover {
  border: 1px solid #888;
}
.el-picker-panel.el-popper .el-time-panel__btn + .el-time-panel__btn {
  margin-left: 4px;
}
/* 캘린더 하단 버튼 */
.el-picker-panel.el-popper .el-button.el-button--text {
  padding: 0 16px;
  color: #5551ce;
}
.el-picker-panel.el-popper .el-button.el-button--text:hover {
  color: #4a47a5;
}
.el-picker-panel.el-popper .el-button.el-button--default {
  padding: 0 16px;
  border: 1px solid #bbb;
  border-radius: 2px;
  line-height: 26px;
  color: #555;
}
.el-picker-panel.el-popper .el-button.el-button--default:hover {
  border: 1px solid #888;
}
.el-picker-panel.el-popper .el-button + .el-button {
  margin-left: 4px;
}
/* 컨텐츠내의 드롭박스 제어 */
.el-popper {
  z-index: 10 !important;
}
/* 팝업내 드롭박스 제어 */
.el-popper[style*="position: fixed;"] {
  z-index: 9990 !important;
}

/* editor */
.editor_comm .note-editor.note-frame {
  border: 1px solid #e1e1e1;
  border-top-color: #222;
}
.tbl_comm .editor_comm .note-editor.note-frame {
  border-top-color: #e1e1e1;
}
.editor_comm .note-frame .note-toolbar * {
  color: #555;
}

/* iframe */
.area_iframe {
  position: relative;
  overflow: hidden;
  height: 900px;
  border-top: 2px solid #222;
  border-bottom: 1px solid #e1e1e1;
}
.purchase_layer .area_iframe {
  height: 100%;
}

/* -- table -- */
.area_fold + .area_view,
.wrap_print + .area_view,
.area_view + .area_view {
  margin-top: 10px;
}
.area_view .tbl_comm + .tbl_comm {
  border-top: 0;
}
/* table 공통 */
.tbl_comm {
  border-top: 1px solid #222;
}
.tbl_comm table {
  min-width: 100%;
  border: 0 none;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}
.tbl_comm thead th {
  padding: 8px 4px;
  border-bottom: 1px solid #e1e1e1;
  font-weight: normal;
  font-size: 12px;
  background-color: #fbfbfb;
  color: #222;
}
.tbl_comm thead th .check_comm {
  margin: 7px 0;
}
.tbl_comm .align_mid {
  vertical-align: middle !important;
}
.tbl_comm .align_mid .group_form .radio_comm {
  margin: 0 4px;
}
.tbl_comm .align_left {
  text-align: left !important;
}
.tbl_comm .align_center {
  text-align: center !important;
}
.tbl_comm .align_right {
  text-align: right !important;
}
.tbl_comm .align_top {
  padding-top: 15px !important;
  height: 25px !important;
  vertical-align: top !important;
}
.tbl_comm .td_ellip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.tbl_comm .td_ellip .link_subject {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;
}
.tbl_comm .td_ellip .link_subject .link_bold {
  font-weight: bold;
}
.tbl_comm .td_ellip .txt_ellip {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tbl_comm .td_break {
  word-wrap: break-word;
  word-break: keep-all !important;
}
.tbl_comm .desc_view {
  padding: 8px 0 6px;
  font-size: 12px;
  line-height: 18px;
}
/* .tbl_comm .td_ellip:after{display:none;position:fixed;z-index:10;margin:-2px 0 0 20px;padding:1px 8px 3px;font-weight:bold;border-radius:2px;background-color:#888;color:#fff;white-space:normal;content:attr(data-ellip)}
.tbl_comm .td_ellip:hover:after{display:block}
.tbl_comm tr:last-child .td_ellip:after{display:block} */
/* 가운데정렬 table */
.tbl_border thead th {
  border-left: 1px solid #e1e1e1;
}
.tbl_border thead th:first-child {
  border-left: 0 none;
}
.tbl_border tbody td + td {
  border-left: 1px solid #e1e1e1;
}
/* table head 고정 - 스크롤 */
.tbl_scroll {
  position: relative;
  padding-top: 49px;
}
.tbl_scroll .inner_scroll {
  overflow-x: hidden;
  overflow-y: auto;
}
.tbl_scroll table {
  border-collapse: inherit;
}
.tbl_scroll thead {
  position: absolute;
  margin-top: -49px;
  z-index: 5;
}
.tbl_scroll thead th {
  box-sizing: border-box;
}
/* fold */
.area_fold_r {
  position: relative;
  padding-bottom: 24px;
}
.area_fold_r .btn_fold {
  position: absolute;
  top: 4px;
  right: 0;
  font-size: 12px;
  line-height: 18px;
  color: #5551ce;
  outline: none;
}
.area_fold_r .btn_fold:hover {
  color: #4a47a5;
}
.area_fold_r .btn_fold .ico_arr_fold {
  margin: 5px 0 0 8px;
}
.area_fold_r.folded {
  overflow: hidden;
  height: 171px;
  padding-bottom: 0;
}
.area_fold_r .bar_fold {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  padding: 5px 0 6px;
  border-top: 1px solid #a8a6e4;
  background-color: #f2f2f8;
  text-align: center;
}
.area_fold_r .bar_fold:hover {
  background-color: #e2e1f4;
}

/* list total */
.list_total {
  padding: 21px 32px 8px;
  line-height: 22px;
  background-color: #f9fafd;
}
.list_total dt {
  float: left;
  font-size: 12px;

  color: #222;
}
.list_total dd {
  overflow: hidden;
  font-size: 15px;
  text-align: right;

  color: #555;
}
.list_total dd + dt,
.list_total dd + dt + dd {
  padding-top: 8px;
}
.list_total .dt_total,
.list_total .dd_total {
  margin-top: 18px;
  padding-top: 13px;
  border-top: 1px solid #e1e1e1;
}
.list_total .dt_total *,
.list_total .dd_total * {
  display: inline-block;
  vertical-align: top;
}
.list_total .dt_total {
  padding-top: 15px;
  font-size: 14px;
  line-height: 20px;
}
/* .list_total .dd_total{font-size:19px;line-height:28px} */
/* .list_total .txt_total{padding:6px 0 3px;font-size:14px;line-height:18px}
.list_total .txt_small{margin-left:8px;font-size:12px;line-height:20px;color:#888}
.list_total .txt_korean{margin-left:24px;font-size:18px;line-height:27px} */
.list_total .txt_price {
  margin-left: 8px;
  padding: 2px 0 3px;
  font-size: 19px;
  line-height: 22px;
}
.list_total .txt_util {
  margin-left: 8px;
  padding: 2px 0 3px;
  font-size: 12px;
  line-height: 22px;
}

/* -- 컨텐츠 -- */
.banner_login {
  position: absolute;
  top: -56px;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #ffe67f;
  background-color: #fffbed;
  text-align: left;
  -webkit-transition: top 0.1s ease-in-out 0.1s;
  transition: top 0.1s ease-in-out 0.1s;
}
.banner_login.on {
  top: 0;
}
.banner_login .desc_banner {
  float: left;
  padding: 17px 70px;
  font-size: 14px;
  line-height: 22px;
  color: #888;
}
.banner_login .btn_close {
  float: right;
  padding: 16px 70px;
  outline: none;
}
.cont_sub {
  min-height: calc(100% - 33px);
  padding: 70px 0 73px;
  box-sizing: border-box;
}
.area_left {
  float: left;
}
.area_right {
  float: right;
}
.section_comm {
  width: 1240px;
  padding: 0 0 64px;
  margin: 0 auto;
}
.section_comm:last-child {
  padding-bottom: 80px;
}
.section_comm + .section_comm {
  margin-top: 32px;
}
.inner_section {
  width: 1240px;
  margin: 0 auto;
}
.head_section {
  padding-top: 48px;
}
.head_section .tit_section {
  display: inline-block;
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 24px;
  color: #555;
  vertical-align: top;
}
.head_section .tit_section .bar_view {
  margin: 8px 12px;
}
.head_section .tit_section .txt_view {
  margin: 5px 0 7px;
  font-size: 14px;
  line-height: 20px;
  color: #888;
}
.head_section .badge_state {
  margin-top: 17px;
  font-weight: 500;
}
.body_section .box_detail {
  padding: 24px 0;
  border-top: 1px solid #222;
  border-bottom: 1px solid #e1e1e1;
}
.body_section .box_detail .inner_detail {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 486px;
  padding: 0 24px;
  font-size: 12px;
  line-height: 20px;
  color: #555;
}
.body_section .box_detail .emph_term {
  display: block;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.body_section .box_detail .tit_term {
  display: block;
  margin: 12px 0 4px;
  font-weight: normal;
}
.body_section .box_detail .tit_term:first-child {
  margin-top: 20px;
}
.body_section .box_detail .list_term > li {
  padding-left: 10px;
  text-indent: -10px;
}
.body_section .box_detail .list_term2 {
  margin: 4px 0;
}
.body_section .box_detail .list_term2 > li {
  padding-left: 10px;
  text-indent: -10px;
}
.body_section .box_detail li:last-child .list_term2 {
  margin-bottom: 0;
}
.body_section .box_detail .desc_date {
  margin-top: 12px;
}
.body_section .box_detail .desc_date + .desc_date {
  margin-top: 4px;
}
.body_section .box_detail .area_file {
  padding: 16px 24px 20px;
  background-color: #fafafa;
}
.body_section .box_detail .tit_file {
  display: block;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #222;
}

/* -- 안내문구--  */
.desc_tip {
  display: inline-block;
  margin-top: 5px;
  font-size: 11px;
  line-height: 18px;
  color: #888;
  vertical-align: top;
}
.desc_tip .ico_exclam,
.desc_tip .ico_exclam_r {
  margin-right: 3px;
}
.tbl_foot {
  overflow: hidden;
}
.tbl_foot .desc_tip {
  display: block;
  margin: 0;
  padding-top: 16px;
  font-size: 12px;
}
.tbl_foot .desc_tip .ico_exclam,
.tbl_foot .desc_tip .ico_exclam_r {
  margin: 1px 4px 0 0;
}

.area_total {
  padding: 5px 48px 5px 0;
  background-color: #f9fafd;
}
.area_total::after {
  content: "";
  clear: both;
  display: block;
}
.area_total dl {
  float: right;
}
.area_total dt {
  float: left;
  margin: 5px 16px 3px 0;
  font-size: 12px;
  line-height: 16px;
  color: #555;
}
.area_total dd {
  float: left;
  font-size: 16px;
  line-height: 24px;
  color: #555;
}
.area_total dd .util_total {
  margin: 5px 0 1px 4px;
  font-size: 12px;
  line-height: 18px;
}
.area_total dd + dt {
  margin-left: 32px;
  padding-left: 32px;
  border-left: 1px solid #e1e1e1;
}
.purchase_layer .area_total {
  background-color: #fffbed;
}
.tbl_head + .area_total {
  border-top: 1px solid #222;
  border-bottom: 1px solid #e1e1e1;
}

.tbl_foot .list_foot {
  overflow: hidden;
}
.tbl_foot .list_foot dt,
.tbl_foot .list_foot dd {
  float: left;
  padding-top: 13px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #555;
  text-align: center;
}

.agree_term {
  overflow: hidden;
  margin-top: 17px;
}
.agree_term .desc_tip {
  margin-top: 0;
  font-size: 12px;
}

.tit_section + .desc_tip {
  margin-top: 10px;
  padding-left: 7px;
}
.tbl_head .desc_tip {
  margin: 14px 0 0 8px;
}
.tbl_head .desc_tip:first-child {
  margin: 0;
}
.tbl_head .tit_tbl + .desc_tip {
  margin-top: 0;
  padding-bottom: 1px;
  line-height: 16px;
  vertical-align: bottom;
}
.tbl_head .area_left .desc_tip:only-child {
  margin-top: 14px;
}
.tbl_head .area_left:only-child .desc_tip:only-child {
  margin-top: 0;
}
.desc_tip .link_tip {
  text-decoration: underline;
}

/* tooltip */
.tooltip_guide {
  display: inline-block;
  position: relative;
  height: 16px;
  margin: 1px 0 0 5px;
  outline: none;
  vertical-align: top;
}
.box_tooltip {
  display: none;
  position: absolute;
  left: 100%;
  z-index: 10;
  width: 404px;
  margin: -25px 0 0 13px;
  padding: 12px 16px 16px;
  border: 1px solid #555;
  border-radius: 2px;
  background-color: #fff;
  text-align: left;
}
.box_tooltip .ico_arr_tooptip {
  position: absolute;
  top: 11px;
  left: -9px;
}
.box_tooltip .tit_tooltip {
  display: block;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #555;
}
.box_tooltip .list_tooltip {
  overflow: hidden;
  margin-top: 6px;
  font-size: 12px;
  line-height: 18px;
}
.box_tooltip .list_tooltip dt {
  float: left;
  width: 67px;
  padding-top: 2px;
  color: #222;
}
.box_tooltip .list_tooltip dd {
  overflow: hidden;
  padding-top: 2px;
  color: #555;
}

.tooltip_guide:hover .box_tooltip {
  display: block;
}

/* list_keyword */
.list_keyword {
  overflow: hidden;
  margin-top: 10px;
}
.list_keyword li {
  float: left;
}
.list_keyword li .btn_keyword {
  margin-top: 4px;
}
.btn_keyword {
  display: inline-block;
  margin-right: 8px;
  padding: 6px 19px;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  font-size: 12px;
  line-height: 18px;
  color: #555;

  outline: none;
  vertical-align: top;
}
.btn_keyword.on {
  padding: 5px 18px;
  border: 2px solid #ffe67f;
  font-weight: 500;
  background-color: #fffbed;
}
.btn_keyword.disabled {
  background-color: #fbfbfb;
  color: #bbb;
}
.list_keyword + .group_form {
  padding: 16px 0 8px;
}

.list_keyword_result {
  margin: 6px 0 7px;
}
.list_keyword_result .txt_keyword {
  display: inline-block;
  margin: 0 8px 0 0;
  padding: 7px 20px;
  border-radius: 16px;
  font-size: 12px;
  line-height: 18px;
  background-color: #fff8d9;
  color: #555;

  vertical-align: top;
}

/* -- group_form -- */
.group_form {
  font-size: 0;
}
.group_form:after {
  display: block;
  visibility: hidden;
  height: 0;
  font-size: 0;
  clear: both;
  content: "";
}
.group_form .tf_comm + .select_comm,
.group_form .tf_comm + .tf_comm,
.group_form .tf_comm + button,
.group_form .select_comm + .select_comm,
.group_form .select_comm + .tf_comm,
.group_form .select_comm + button,
.group_form .select_comm + .area_search,
.group_form .calendar_comm + .select_comm,
.group_form button + button,
.group_form button + .tf_comm,
.group_form .radio_comm + .calendar_comm,
.group_form .radio_comm + .area_search,
.group_form .area_search + button {
  margin-left: 8px;
}
.group_form .dialogue_comm + .btn_search,
.group_form .tf_comm + .btn_search {
  margin-left: 4px;
}
.group_form .select_comm + .check_comm,
.group_form .calendar_comm + button,
.group_form .radio_comm + button {
  margin-left: 16px;
}
.group_form .radio_comm + .txt_view_r {
  padding-left: 32px;
}
.group_form .radio_comm,
.group_form .check_comm {
  padding: 7px 0;
}
.group_form .txt_ellip {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.group_form .txt_view {
  display: inline-block;
  font-size: 12px;
  vertical-align: top;
}
.group_form .txt_view_l {
  display: inline-block;
  padding-right: 8px;
  font-size: 12px;
  line-height: 32px;
  vertical-align: top;
}
.group_form strong.txt_view_l,
.group_form strong.txt_view_c {
  font-weight: normal;
  color: #222;
}
.group_form strong.txt_view_r {
  padding-right: 12px;
  font-weight: 500;
}
.group_form .txt_view_r {
  display: inline-block;
  padding-left: 8px;
  font-size: 12px;
  line-height: 32px;
  vertical-align: top;
}
.group_form .txt_view_r .ico_exclam {
  margin: 8px 4px 8px 0;
}
.group_form .txt_view_r + .tf_comm {
  margin-left: 8px;
}
.group_form .txt_view_r + button {
  margin-left: 16px;
}
.group_form span.txt_view_r.tc_red {
  font-size: 11px;
}
.group_form .txt_view_c {
  display: inline-block;
  font-size: 12px;
  line-height: 32px;
  vertical-align: top;
  text-align: center;
}
.group_form .bar_view {
  display: inline-block;
  width: 1px;
  height: 16px;
  margin: 8px 16px;
  background-color: #e1e1e1;
  vertical-align: top;
}
.group_form .tf_comm + .bar_view {
  margin-left: 24px;
}
.group_form .desc_view {
  display: inline-block;
  vertical-align: top;
}
.group_form .desc_view + .desc_tip {
  margin: 8px 0 0 8px;
}
.group_form + .group_form {
  margin-top: 8px;
}

/* util */
.area_util {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  padding: 8px 24px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

/* list_tag_ldap */
.list_tag_ldap {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  height: 53px;
  margin: 4px -24px -8px 0;
  padding: 0 0 8px 0;
}
.list_tag_ldap li {
  float: left;
  margin: 8px 8px 0 0;
  vertical-align: top;
}
.btn_tag_ldap {
  display: inline-block;
  padding: 5px 12px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 18px;
  background-color: #f6f6fc;
  color: #5551ce;
  text-align: left;
  vertical-align: top;
}
.btn_tag_ldap .ico_cross3 {
  margin: 5px 0 5px 8px;
}
.list_tag_ldap .txt_tag {
  display: block;
  padding: 5px 12px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 18px;
  background-color: #f6f6fc;
  color: #555;
}
.list_tag_view {
  height: 64px;
  margin-top: -8px;
}

/* -- loading -- */
.area_loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  text-align: center;
  font-size: 0;
  white-space: nowrap;
}
.area_loading:after {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.ico_loading {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}
