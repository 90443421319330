@charset "utf-8";
/* font */
/* noto-sans-kr-100 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('../font/noto-sans-kr-v27-korean-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../font/noto-sans-kr-v27-korean-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/noto-sans-kr-v27-korean-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-100.woff') format('woff'), /* Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-100.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-300 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../font/noto-sans-kr-v27-korean-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../font/noto-sans-kr-v27-korean-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/noto-sans-kr-v27-korean-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-300.woff') format('woff'), /* Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-300.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-regular - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../font/noto-sans-kr-v27-korean-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../font/noto-sans-kr-v27-korean-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/noto-sans-kr-v27-korean-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-regular.woff') format('woff'), /* Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-regular.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-500 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../font/noto-sans-kr-v27-korean-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../font/noto-sans-kr-v27-korean-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/noto-sans-kr-v27-korean-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-500.woff') format('woff'), /* Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-500.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-700 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../font/noto-sans-kr-v27-korean-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../font/noto-sans-kr-v27-korean-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/noto-sans-kr-v27-korean-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-700.woff') format('woff'), /* Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-700.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
/* noto-sans-kr-900 - korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('../font/noto-sans-kr-v27-korean-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../font/noto-sans-kr-v27-korean-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/noto-sans-kr-v27-korean-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-900.woff') format('woff'), /* Modern Browsers */
       url('../font/noto-sans-kr-v27-korean-900.svg#NotoSansKR') format('svg'); /* Legacy iOS */
}
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../font/materialicons-regular.eot); /* For IE6-8 */
    src: url(../font/MaterialIcons-Regular.woff2) format('woff2'),
         url(../font/materialicons-regular.woff) format('woff'),
         url(../font/MaterialIcons-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'Material Icons Outlined';
    src: url(../font/materialiconsoutlined-regular.woff2) format("woff2");
  }
  
  @font-face {
    font-family: "element-icons";
    src: url(../font/element-icons.woff) format("woff"),
      url(../font/element-icons.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "element-icons";
    src: url(../font/element-icons.woff) format("woff"),
      url(../font/element-icons.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  