














































/* -- 레이어 팝업 -- */
.purchase_layer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9990;
  min-width: 320px;
  padding: 50px 0;
  font-size: 0;
  background-color: rgba(34, 34, 34, 0.6);
  text-align: center;
  white-space: nowrap;
}
.purchase_layer:after {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
/* 공통 */
.purchase_layer::v-deep .inner_comm_layer {
  display: inline-block;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
  background-color: #fff;
  white-space: normal;
  vertical-align: middle;
}
.purchase_layer::v-deep .inner_comm_layer .body_section > .area_view + .group_btn {
  padding: 32px 0 8px;
  text-align: right;
}
.purchase_layer::v-deep .inner_comm_layer .layer_body > .group_btn {
  padding: 15px 0;

  text-align: center;
}
.purchase_layer::v-deep .inner_comm_layer .layer_body > .group_btn button + button {
  margin-left: 8px;
}
.purchase_layer::v-deep .layer_body {
  position: relative;
}
.purchase_layer::v-deep .section_sticky {
  /* position: absolute; */
  height: 80px;
}
.purchase_layer::v-deep .section_sticky .inner_section {
  padding: 20px 0;
}
.purchase_layer::v-deep .section_sticky .box_sticky {
  box-shadow: 0 -2px 8px 0 rgba(34, 34, 34, 0.15);
}
/* 알럿형 */
.purchase_layer::v-deep .inner_alert_layer {
  min-width: 344px;
  max-width: 424px;
}
.purchase_layer::v-deep .inner_alert_layer .layer_body {
  padding: 56px 40px 32px;
}
.purchase_layer::v-deep .inner_alert_layer .layer_body .desc_alert {
  font-size: 13px;
  line-height: 21px;
}
.purchase_layer::v-deep .inner_alert_layer .layer_body .group_btn {
  padding-top: 40px;
}
/* 테이블형 */
.purchase_layer::v-deep .inner_table_layer {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 100%;
  max-width: 100%;
  padding: 0 20px;
  text-align: left;
}

.purchase_layer::v-deep .inner_table_layer .group_btn {
  position: sticky;
  left: 0;
  bottom: 0;
  border-top: 1px solid #222;
  background-color: #ffffff;
}
.purchase_layer::v-deep .inner_table_layer .layer_body {
  padding: 15px 12px 15px;
  box-sizing: border-box;
}
.purchase_layer::v-deep .inner_table_layer .section_comm {
  width: auto;
  padding: 0 0 20px;
}
.purchase_layer::v-deep .inner_table_layer .head_section {
  padding-top: 0;
}
.purchase_layer::v-deep .inner_table_layer .head_section .tit_section {
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
}
.purchase_layer::v-deep .inner_table_layer .head_section h4.tit_section {
  padding: 16px 0 12px;
  font-size: 16px;
  line-height: 24px;
}
.purchase_layer::v-deep .inner_table_layer .box_filter {
  border-top: 1px solid #222;
}
.purchase_layer::v-deep .inner_table_layer .box_filter dl {
  padding: 0 24px 12px 24px;
  padding-left: 144px;
}
.purchase_layer::v-deep .inner_table_layer .box_filter dt,
.purchase_layer::v-deep .inner_table_layer .box_filter dd {
  padding-top: 12px;
}
.purchase_layer::v-deep .inner_table_layer .box_filter + .group_btn {
  padding: 16px 0;
}
.purchase_layer::v-deep .inner_table_layer .box_password dl {
  padding: 16px 32px 32px 32px;
}
/* .purchase_layer::v-deep .inner_table_layer .tbl_list{overflow-x:hidden;overflow-y:auto} */
.purchase_layer::v-deep .inner_table_layer .tbl_comm thead th {
  text-align: center;
  height: 49px;
}
.purchase_layer::v-deep .inner_table_layer .desc_alert {
  margin-top: 16px;
  padding: 16px 34px 15px;
  border: 1px solid #5551ce;
  font-size: 12px;
  line-height: 18px;
  background-color: #f6f6fc;
  color: #5551ce;
}
.purchase_layer::v-deep .inner_table_layer .desc_alert.tc_red {
  border: 1px solid #f8626c;
  background-color: #fef7f7;
}
.purchase_layer .area_view + .area_view {
  margin-top: 32px;
}

.purchase_layer::v-deep .inner_print_layer {
  overflow: hidden;
  position: static;
  width: 1090px;
  height: 100%;
  text-align: initial;
}
/* .purchase_layer::v-deep .inner_print_layer .layer_head{} */
.purchase_layer::v-deep .inner_print_layer .section_comm {
  padding-bottom: 0;
}
.purchase_layer::v-deep .inner_print_layer .tit_layer {
  padding: 24px 48px 17px;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #888;
}
.purchase_layer::v-deep .inner_print_layer .ico_preview {
  margin: 3px 8px 0 0;
}
.purchase_layer::v-deep .inner_print_layer .layer_body {
  overflow-y: auto;
  height: calc(100% - 60px);
}

.purchase_layer::v-deep .inner_print_layer .section_comm {
  width: auto;
}
.purchase_layer::v-deep .inner_print_layer .inner_section {
  width: 100%;
}
